/**
=========================================================
* 508Media TikTok Tool - v2.2.0
=========================================================

* Product Page: https://www.egeadcompany.com/product/material-dashboard-react
* Copyright 2023 Dev Egead Company (https://www.egeadcompany.com)

Coded by www.egeadcompany.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBadge from "components/MDBadge";

// 508Media TikTok Tool components
import MDBox from "components/MDBox";

// 508Media TikTok Tool example components
import { styled } from '@mui/system';
import MDButton from "components/MDButton";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import DataTable from 'examples/Tables/DataTable';
import moment from "moment";
import Papa from "papaparse";
import PropTypes from 'prop-types';
import { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { NotificationManager } from "react-notifications";
import "react-notifications/lib/notifications.css";
import { useDispatch, useSelector } from 'react-redux';
import * as XLSX from 'xlsx';
import { checkLink, editGG, getData, logout } from '../../features/slices';
import data2 from './constants';
import data3 from './constants2';
import LoadingScreen from './Loading';

//outside
const ColorItem = ({ color }) => {
  return (
    <div style={{ marginRight: "3px", textShadow: "1px 1px 1px rgba(0, 0, 0, 0.7)", color: color == "Light Pink" ? "#FFB6C1" : color == "Light Blue" ? "#ADD8E6" : color == "Ash" ? "#ecece0" : color == "Natural" ? "#E5D3BF" : color == "Beige" ? "#F5F5DC" : color.toLowerCase() }}>
      {`${color.replace(" ", "")}`}
    </div>
  );
};
ColorItem.propTypes = {
  color: PropTypes.string.isRequired,
};

const StyledFormControl = styled(FormControl)({
  width: 225,
});

const dropdown = [
  { acc: "Nữ - T-shirts (601302)", value: "T-shirts (601302)", value2: "Women's Tops/T-shirts" },
  { acc: "Nữ - Blouses & Shirts (601265)", value: "Blouses & Shirts (601265)", value2: "Women's Tops/Blouses & Shirts" },
  { acc: "Nữ - Hoodies", value: "Hoodies & Sweaters (601213) ", value2: "Women's Tops/Hoodies & Sweaters/Women's Hoodies" },
  { acc: "Nữ - Sweaters", value: "Hoodies & Sweaters (601213)", value2: "Women's Tops/Hoodies & Sweaters/Women's Pullover Sweatshirts" },
  { acc: "Nữ - Tank Top", value: "Vest, Tank & Tube Tops (843400)", value2: "Women's Tops/Vest, Tank & Tube Tops" },
  { acc: "Nam - Shirts (601195)", value: "Shirts (601195)", value2: "Men's Tops/Shirts/Men's Casual Shirts/Short-sleeve Causal Shirts" },
  { acc: "Nam - T-shirts (601226)", value: "T-shirts (601226)", value2: "Men's Tops/T-shirts/Men's Short-sleeve T-shirts" },
  { acc: "Name - Hoodies", value: "Hoodies & Sweaters (601295) ", value2: "Men's Tops/Hoodies & Sweaters/Men's Hoodies" },
  { acc: "Nam - Sweaters", value: "Hoodies & Sweaters (601295)", value2: "Men's Tops/Hoodies & Sweaters/Men's Sweatshirts" },
  { acc: "Name - Tank Top", value: "Vest, Tank & Tube Tops (843400)", value2: "Men's Underwear/Vests/Men's Tanks" },
  { acc: "Mug (600042)", value: "Mugs (600042)", value2: "Drinkware/Mugs" },
  { acc: "Women's Tote Bags (903176)", value: "Women's Tote Bags (903176)", value2: "Women's Bags/Tote Bags" },
  { acc: "Tumbler (600048)", value: "Water Bottles (600048)", value2: "Drinkware/Water Bottles" },
  { acc: "Men Short (601196)", value: "Shorts (601196)", value2: "Men's Bottoms/Shorts" },
  { acc: "Ornament", value: "Speciality Plaques & Signs (1237264)", value2: "Home Decor / Plaques & Signs / Speciality Plaques & Signs" },


]

const shirtsDes = `🌟🌟 Product Description 🌟🌟

Unisex Crew Neck T-Shirts

Elevate your wardrobe with our premium unisex crew neck T-shirts. Crafted with utmost care, these T-shirts offer unparalleled comfort and style. Available in a variety of solid and heather colors, our T-shirts are designed to suit every taste and preference.

Composition: Solid colors are made of 99.99% cotton for a soft and breathable feel, while heather colors feature a blend of 51.99% cotton and 48% polyester for added durability and stretch.
🌟🌟 Care and Washing Instructions 🌟🌟

To ensure the longevity of your T-shirt, we recommend washing it inside out in cold water. Avoid using bleach or dry cleaning methods, and refrain from ironing directly on the design to maintain its pristine appearance wash after wash.

🌟🌟 How to Order 🌟🌟

Browse through our listing and explore all available photos to find the perfect T-shirt for you.
Select your preferred size and color from the options provided in the listing photos.
Click "Add to Cart" and repeat the process for any additional T-shirts you wish to purchase.
Once you've finalized your selection, proceed to checkout to complete your order effortlessly.
Embrace equality and showcase your support with our range of inclusive designs, including the "Equal rights for others does not mean fewer rights for you" T-shirt featuring vibrant LGBT rainbow, Black Rainbow, and Transgender Rainbow designs.

Shipping typically takes 3-5 days during office hours, ensuring prompt delivery of your order.

If you have any inquiries or require assistance, please don't hesitate to reach out to us directly via TikTok Shop messaging. Thank you for choosing our T-shirts to express yourself with style and inclusivity!`

const hoodiesDes = `⭐⭐ Product Description ⭐⭐

Unisex Crew Neck  T Shirts

- Our unisex crew neck  T shirts are high quality. Solid colors are %99.99 cotton, and heather colors are %51.99 cotton and %48 polyester.

⭐⭐ Caring and Washing instructions ⭐⭐

Wash item inside out in cold water, do not bleach, do not dry clean, do not iron directly on the design.

⭐⭐ How to Order ⭐⭐

1-) Please check all the photos from the listing.

2-) Please choose your shirt size and color. (Shirt sizes are shown on the listing photos)

3-) Click add to cart. If you would like to order more than one shirt, you can easily go back to the listing and do the same thing for the other shirts you would like to purchase.

4-) Then please click Proceed to checkout. You are all set !

Equal rights for others does not mean fewer rights for you T shirt,  LGBT Rainbow, Black Rainbow, Transgender Rainbow, Pride

Shipping takes 3-5 days according to office hours 

If you have any questions, please contact us, please send a message directly via TIKTOK SHOP . Thank you !`

const sweaterDes = `⭐⭐ Product Description ⭐⭐

Unisex Crew Neck  SweatShirts

- Our unisex crew neck  SweatShirts are high quality. Solid colors are %99.99 cotton, and heather colors are %51.99 cotton and %48 polyester.

⭐⭐ Caring and Washing instructions ⭐⭐

Wash item inside out in cold water, do not bleach, do not dry clean, do not iron directly on the design.

⭐⭐ How to Order ⭐⭐

1-) Please check all the photos from the listing.

2-) Please choose your shirt size and color. (Shirt sizes are shown on the listing photos)

3-) Click add to cart. If you would like to order more than one shirt, you can easily go back to the listing and do the same thing for the other shirts you would like to purchase.

4-) Then please click Proceed to checkout. You are all set !


Shipping takes 3-5 days according to office hours 

If you have any questions, please contact us, please send a message directly via TIKTOK SHOP . Thank you !`

const tankDes = `Description

Women's Ideal Racerback Tank

These exquisitely personalized tank tops will make your day a little more special and meaningful. They also make great gifts for any occasion and especially hot summer days.

The detail information of product:

- Color: As depicted in the image provided

- Size (US): Available in sizes from XS to 2XL - please refer to the size chart carefully before ordering

- Material: 6.1 oz., 100% American cotton

- Dyed clothes feel vibrant and almost shrink-free at home.

- Soft cotton elastic material with 100% cotton yarn

- Comfortable fit

- Embossed seams, classic width, hemmed neckline

- Cross shoulder bandage

- Signature twill label

- Made with OEKO-TEX certified low impact dyes

STORAGE INSTRUCTIONS:

- Machine wash with cold water

- Do not use bleach

- Tumble dry on low setting

- Iron at low temperature with the inside of the shirt

- Never iron directly on the design`

const shortDes = `<p>Get ready for an active summer with our Hawaiian shorts. Our custom Hawaiian shorts are stylish and very comfortable to wear that help you get through the scorching summer more pleasantly.</p><ul><li>Material: 100% Woven Polyester – Moisture-wicking, lightweight, and durable fabric that offers a comfortable feeling when wearing.</li><li>2 side pockets for you to conveniently carry keys, and phones around.</li><li>Elastic waist with drawstring for better securing and easy adjustment to fit your waist.</li><li>The over-the-knee length makes our custom Hawaiian shorts relaxed and comfortable for summer wear.</li><li>Sublimation printing technology provides long-lasting and vibrant colors for our custom Hawaiian Shorts.</li></ul><p>Our Hawaiian Shorts makes the perfect bottom to wear every day, to go swimming, or to simply lounge around. Pair these Hawaiian shorts with our Hawaiian shirts and instantly capture the summer spirit spot on.</p>`

const toteDes = `<p>This 100% cotton canvas is perfect for your next project. Available in natural and black colors, this heavy fabric (12 oz/yd² (406.9 g/m²)) is durable and versatile.</p><p>Material: Made with 100% cotton canvas</p><p>Colors: Available in natural and black</p><p>Durable: Heavy fabric with 12 oz/yd² (406.9 g/m²) weight</p><p>Sewn-in label: Easy to identify for your convenience.</p><p>The heavy weight of the fabric ensures that it will stand up to wear and tear over time.</p><p>You won't regret choosing this high-quality cotton canvas for your next project!</p>`
const mugDes = `<p>Perfect for coffee, tea and hot chocolate, this classic shape white, durable ceramic mug comes in the most popular size. High quality sublimation printing makes it an appreciated gift to every true hot beverage lover.</p><ul><li>White ceramic</li><li>15oz (0.44 l) or 11oz (0.33 l)</li><li>Rounded corners</li><li>C-Handle</li><li>Lead and BPA-free</li></ul><p><strong>Care instructions</strong></p><p>Clean in dishwasher or wash by hand with warm water and dish soap.</p><br><p><strong>Size guide:</strong></p><p><strong>15OZ</strong></p><p>Height, in: 4.72 (12cm)</p><p>Diameter, in: 3.39 (8.6cm)</p><p>Diameter (with handle), in :4.92 (12.50cm)</p><p><strong>11OZ</strong></p><p>Height, in: 3.74 (9.50cm)</p><p>Diameter, in : 3.15 (8.00cm)</p>`
const tumblerDes = `<p>This tumbler is an excellent choice for daily use to keep 20oz of your favorite beverages hot or cold thanks to the double-wall insulation. Meanwhile, the stainless steel construction provides excellent durability and it's dishwasher safe for added convenience.</p><ul><li>Materials: stainless steel (body), plastic and rubber (lid)</li><li>One size: 20oz (0.59 l)</li><li>Rounded corners</li><li>Vacuum insulated steel body with a clear push on lid with a rubber gasket</li><li>Glossy finish</li></ul><p>Hot or Cold: Perfect for everyday use, the tumbler's design provides an excellent seal which keeps beverages hot or cold</p><p>Stainless Steel :Double wall interior makes the tumbler durable, resistant to rust, staining and corrosion</p><p>Care instructions <strong>:</strong>Clean in dishwasher or wash by hand with warm water and dish soap.</p><br><p>Height, in 6.77 (17.20cm)</p><p>Diameter, in 2.83 - 3.39 (7.20 - 8.60cm)</p>`
const hwDes = `A Hawaiian shirt is a breezy, summer essential – Perfect for trips to wear on vacation to tropical destinations.

Material: 100% woven polyester fabric offers outstanding durability, insulation, and wrinkle resistance. This lightweight, breathable, moisture-wicking fabric is perfect to keep you cool during the summer.
Simple and comfortable button closure makes it easy to style and layer with other trendy items.
Signature open Cuban collar with short sleeve and relaxed fit looks casual and fashionable.
The color of our custom aloha shirts could be slightly different on the screen than in real life.
Short-sleeve Hawaiian Shirt is great to mix and match with shorts, jeans, or layer with other items to complement your outfits.

Tracking Country Origin: US.`

const ornaDes = `Description is null!`
const sizes = [
  {
    "sizechart": "https://crawleretsy.nyc3.digitaloceanspaces.com/size-chart/T-shirts.PNG",
    "name": "T-Shirts",
    "height": "10",
    "lenght": "3",
    "weight": "0.45",
    "width": "10"
  },
  {
    "sizechart": "https://crawleretsy.nyc3.digitaloceanspaces.com/size-chart/T-shirts.PNG",
    "name": "Shirt, Hawwaian Shirt",
    "height": "10",
    "lenght": "3",
    "weight": "0.45",
    "width": "10"
  },
  {
    "sizechart": "https://crawleretsy.nyc3.digitaloceanspaces.com/size-chart/hoodies.PNG",
    "name": "Hoodies",
    "height": "12",
    "lenght": "4",
    "weight": "1.6",
    "width": "10"
  },
  {
    "sizechart": "https://crawleretsy.nyc3.digitaloceanspaces.com/size-chart/sweatshirt.PNG",
    "name": "Sweatshirts",
    "height": "12",
    "lenght": "4",
    "weight": "1.6",
    "width": "10"
  },
  {
    "sizechart": "https://imagecloud.nyc3.digitaloceanspaces.com/photo_2024-05-28_14-31-15.jpg",
    "name": "Tank Top",
    "height": "12",
    "lenght": "4",
    "weight": "1.6",
    "width": "10"
  },
  {
    "sizechart": "",
    "name": "Tumbler",
    "height": "7",
    "lenght": "4",
    "weight": "0.6",
    "width": "4"
  },
  {
    "sizechart": "",
    "name": "Tote",
    "height": "7",
    "lenght": "2",
    "weight": "0.45",
    "width": "7"
  },
  {
    "sizechart": "",
    "name": "Mug",
    "height": "5",
    "lenght": "4",
    "weight": "0.6",
    "width": "10"
  },
  {
    "sizechart": "https://p19-oec-ttp.tiktokcdn-us.com/tos-useast5-i-omjb5zjo8w-tx/907dd027a6874ba9b50f010f0ffa85e9~tplv-omjb5zjo8w-origin-jpeg.jpeg?from=522366036&height=4681&width=4345",
    "name": "Men Short",
    "height": "3",
    "lenght": "6",
    "weight": "0.5",
    "width": "6"
  },
  {
    "sizechart": "https://p19-oec-ttp.tiktokcdn-us.com/tos-useast5-i-omjb5zjo8w-tx/907dd027a6874ba9b50f010f0ffa85e9~tplv-omjb5zjo8w-origin-jpeg.jpeg?from=522366036&height=4681&width=4345",
    "name": "Ornament",
    "height": "1",
    "lenght": "5",
    "weight": "0.2",
    "width": "5"
  },
]

function removeLinksFromString(inputString) {
  if (inputString) {
    const linkRegex = /https?:\/\/[^\s]+/g;
    const stringWithoutLinks = inputString.replace(linkRegex, '');
    return stringWithoutLinks;
  } else {
    return inputString
  }
}

//
function Tables() {
  const dispatch = useDispatch()
  const isLoading = useSelector((state) => state.user.isLoading);
  const size1 = useSelector((state) => state.user.size);
  const color1 = useSelector((state) => state.user.color);
  const description1 = useSelector((state) => state.user.description);
  const time = useSelector((state) => state.user.time);
  const hash = useSelector((state) => state.user.hash);
  const email = useSelector((state) => state.user.email);
  const [data, setData] = useState([]);
  const [dataError, setDataError] = useState([]);
  const [price, setPrice] = useState(22.49);
  const [size, setSize] = useState(size1);
  const [color, setColor] = useState(color1);
  const [description, setDescription] = useState(description1);
  const [selectedSize, setSelectedSize] = useState(sizes[0]); // Mặc định là kích cỡ đầu tiên
  const [selectedValue, setSelectedValue] = useState(dropdown[0].value); // Mặc định là kích cỡ đầu tiên
  const [selectedValue2, setSelectedValue2] = useState(dropdown[0].value2); // Mặc định là kích cỡ đầu tiên
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [isWoo, setIsWoo] = useState(true);
  const [link, setLink] = useState("");
  const [linkStatus, setLinkStatus] = useState("");
  const [fileName, setFileName] = useState('');
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleOk = async () => {
    const res = await dispatch(editGG({ email, size, color }))
    if (res.payload.status == 1) {
      setSize(res.payload.size)
      setColor(res.payload.color)
    }
    handleClose();
  };

  const handleClose1 = () => {
    setOpen1(false);
  };

  const handleOk1 = async () => {
    const res = await dispatch(editGG({ email, description }))
    if (res.payload.status == 1) {
      setDescription(res.payload.description)
    }
    handleClose1();
  };
  const handleChangeSizes = (event) => {
    const selectedSizeName = event.target.value;
    const selectedSizeObject = sizes.find(size => size.name === selectedSizeName);
    if (selectedSizeName == "T-Shirts" || selectedSizeName == "Shirt, Hawwaian Shirt") {
      setSize("S|M|L|XL|2XL|3XL|4XL|5XL")
    } else if (selectedSizeName == "Men Short") {
      setSize("S|M|L|XL|2XL|3XL|4XL|5XL")
    } else if (selectedSizeName == "Tank Top") {
      setSize("S|M|L|XL|2XL")
    } else if (selectedSizeName == "Mug") {
      setSize("11oz|15oz")
    } else if (selectedSizeName == "Tumbler") {
      setSize("12oz")
    } else if (selectedSizeName == "Tote") {
      setSize("No Size")
    } else if (selectedSizeName == "Onarment") {
      setSize("8x8 Inches")
    } else {
      setSize("S|M|L|XL|2XL|3XL")
    }
    setSelectedSize(selectedSizeObject);
  };
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    const _value2 = dropdown.filter(items => items.value == event.target.value)
    setSelectedValue2(_value2[0].value2)
  };
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFileName(file.name);
    }
    const reader = new FileReader();
    reader.onload = (event) => {
      const text = event.target.result;
      Papa.parse(text, {
        header: false,
        skipEmptyLines: true,
        complete: function (results) {
          const csvData = results.data;
          const columnAData = csvData
            .filter((row) => row[0] !== undefined && row[0] !== "")
            .map((row) => row[0]);
          setData(columnAData);
        },
      });
    };
    reader.readAsText(file);
  };

  const handleCheckLink = async () => {
    setLinkStatus("")
    const res = await dispatch(checkLink({ url: link }))
    setLinkStatus(res.payload)
  }
  function chunkArray(array, size) {
    const chunkedArr = [];
    let index = 0;
    while (index < array.length) {
      chunkedArr.push(array.slice(index, index + size));
      index += size;
    }
    return chunkedArr;
  }

  const handleExport = async () => {
    const chunked = chunkArray(data, 10);
    setDataError([])
    let allData = []
    for (let [index, _url] of chunked.entries()) {
      console.log(index)
      NotificationManager.success(`Crawling ${index}/${chunked.length}!`, "Alert", 2000);
      const response = await dispatch(getData({ site: isWoo, urls: _url, sizechart: selectedSize.sizechart, category: selectedSize.name, height: selectedSize.height, length1: selectedSize.lenght, width: selectedSize.width, weight: selectedSize.weight, size, price, color, hash, time, email }))
      if (response.payload?.status != 1) {
        allData = [...response.payload.data, ...allData]
        setDataError([...dataError, ...response.payload.dataError])
      } else {
        NotificationManager.error('Tài khoản đang đăng nhập ở nơi khác, Vui lòng đăng nhập lại!', 'Error', 3000);
        dispatch(logout())
        break
      }
    }

    if (allData.length) {
      let dataVariable = []
      const sizeList = size.split("|");
      const colorList = color.split("|");
      let stt = 0
      const header = [
        "category", "brand", "product_name", "product_description", "parcel_weight", "parcel_length",
        "parcel_width", "parcel_height", "delivery", "gtin_type", "gtin_code", "property_value_1",
        "property_value_2", "property_1_image", "price", "quantity",
        "seller_sku", "main_image", "image_2", "image_3", "image_4", "image_5", "image_6", "image_7",
        "image_8", "image_9", "size_chart", "product_property/100157", "product_property/100198",
        "product_property/100393", "product_property/100394", "product_property/100395",
        "product_property/100396", "product_property/100397", "product_property/100398",
        "product_property/100399", "product_property/100400", "product_property/100403", "product_property/101619", "product_property/101610", "aimed_product_status"
      ]

      for (let [index, row] of allData.entries()) {
        const infoDescription = selectedSize.name == "T-Shirts" ? shirtsDes : selectedSize.name == "Hoodies" ? hoodiesDes : selectedSize.name == "Sweatshirts" ? sweaterDes : selectedSize.name == "Tank Top" ? tankDes : selectedSize.name == "Mug" ? mugDes : selectedSize.name == "Tote" ? toteDes : selectedSize.name == "Tumbler" ? tumblerDes : selectedSize.name == "Men Short" ? shortDes : selectedSize.name == "Shirt, Hawwaian Shirt" ? hwDes : row[3]
        const capitalized = row[2].split('-')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join('-');

        const skuTime = moment().unix()
        let sku = 0
        for (const _size of sizeList) {
          let priceSize = price
          if (selectedSize.name == "T-Shirts" || selectedSize.name == "Tank Top" || selectedSize.name == "Shirt, Hawwaian Shirt") {
            priceSize = _size == "2XL" ? parseFloat(price) + 1 : _size == "3XL" ? parseFloat(price) + 2 : _size == "4XL" ? parseFloat(price) + 3 : _size == "5XL" ? parseFloat(price) + 4 : parseFloat(price)
          } else if (selectedSize.name == "Men Short") {
            priceSize = parseFloat(price)
          } else {
            priceSize = _size == "2XL" ? parseFloat(price) + 2 : _size == "15oz" ? parseFloat(price) + 8 : _size == "15OZ" ? parseFloat(price) + 8 : _size == "3XL" ? parseFloat(price) + 4 : _size == "4XL" ? parseFloat(price) + 6 : _size == "5XL" ? parseFloat(price) + 8 : parseFloat(price)
          }
          for (const _color of colorList) {
            // dataVariable.push([selectedValue2, row[1], capitalized, removeLinksFromString(infoDescription), row[17], row[18], row[19], row[20], row[21], row[22], row[23], row[24], "", row[10], "", _color, _size, "", selectedSize.weight, selectedSize.lenght, selectedSize.width, selectedSize.height, "", priceSize, "50", `${skuTime}${stt}-${sku}`, selectedSize.sizechart, "", "", "", "", "", "", "", "", "", "", "", "Not Sure", "", "", "", "", "None", "", "", "", "", "", "", "", "", "Active(1)"])
            dataVariable.push([selectedValue2, row[1], capitalized, removeLinksFromString(infoDescription), row[17], "", "", "", "", "", "", "", "", row[10], "", _color, _size, "", selectedSize.weight, selectedSize.lenght, selectedSize.width, selectedSize.height, "", priceSize, "50", `${skuTime}${stt}-${sku}`, selectedSize.sizechart, "", "", "", "", "", "", "", "", "", "", "", "Not Sure", "", "", "", "", "None", "", "", "", "", "", "", "", "", "Active(1)"])
            sku++
          }
        }
        stt++
      }
      if (allData.length) {
        const combinedData = [...data3, ...dataVariable];
        const ws = XLSX.utils.aoa_to_sheet(combinedData);
        const wb = XLSX.utils.book_new();
        const combinedData2 = [
          [
            "Women's Tops/T-shirts",
            601302
          ],
          [
            "Women's Tops/Vest, Tank & Tube Tops",
            843400
          ],
          [
            "Women's Tops/Blouses & Shirts",
            601265
          ],
          [
            "Women's Tops/Hoodies & Sweaters/Women's Hoodies",
            901776
          ],
          [
            "Women's Tops/Hoodies & Sweaters/Women's Pullover Sweatshirts",
            901648
          ],
          [
            "Men's Tops/Shirts/Men's Casual Shirts/Short-sleeve Causal Shirts",
            1790096
          ],
          [
            "Men's Tops/T-shirts/Men's Short-sleeve T-shirts",
            1165840
          ],
          [
            "Men's Tops/Hoodies & Sweaters/Men's Hoodies",
            1167504
          ],
          [
            "Men's Tops/Hoodies & Sweaters/Men's Sweatshirts",
            1167376
          ],
          [
            "Men's Underwear/Vests/Men's Tanks",
            1165584
          ],
          [
            "Men's Bottoms/Shorts",
            601196
          ],
          [
            "Women's Bags/Tote Bags",
            903176
          ],
          [
            "Drinkware/Water Bottles",
            600048
          ],
          [
            "Drinkware/Mugs",
            600042
          ],
          [
            "Home Decor / Plaques & Signs / Speciality Plaques & Signs",
            1237264
          ],
        ];
        const ws2 = XLSX.utils.aoa_to_sheet(combinedData2);
        XLSX.utils.book_append_sheet(wb, ws, 'Template');
        const ws2Props = {
          '!props': {
            hidden: true
          }
        };
        ws2['!props'] = ws2Props['!props'];
        XLSX.utils.book_append_sheet(wb, ws2, 'Category');
        XLSX.writeFile(wb, `new-tiktok_${moment().unix()}.xlsx`);
        dataVariable = []
        stt = 0
      } else {
        NotificationManager.error('Data cào vể rỗng!', 'Error', 3000);
      }
      for (let [index, row] of allData.entries()) {
        const infoDescription = selectedSize.name == "T-Shirts" ? shirtsDes : selectedSize.name == "Hoodies" ? hoodiesDes : selectedSize.name == "Sweatshirts" ? sweaterDes : selectedSize.name == "Tank Top" ? tankDes : selectedSize.name == "Mug" ? mugDes : selectedSize.name == "Tote" ? toteDes : selectedSize.name == "Tumbler" ? tumblerDes : selectedSize.name == "Men Short" ? shortDes : selectedSize.name == "Shirt, Hawwaian Shirt" ? hwDes : row[3]
        const capitalized = row[2].split('-')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join('-');
        const skuTime = moment().unix()
        let sku = 0
        for (const _size of sizeList) {
          let priceSize = price
          if (selectedSize.name == "T-Shirts" || selectedSize.name == "Tank Top" || selectedSize.name == "Shirt, Hawwaian Shirt") {
            priceSize = _size == "2XL" ? parseFloat(price) + 1 : _size == "3XL" ? parseFloat(price) + 2 : _size == "4XL" ? parseFloat(price) + 3 : _size == "5XL" ? parseFloat(price) + 4 : parseFloat(price)
          } else if (selectedSize.name == "Men Short") {
            priceSize = parseFloat(price)
          } else {
            priceSize = _size == "2XL" ? parseFloat(price) + 2 : _size == "15oz" ? parseFloat(price) + 8 : _size == "15OZ" ? parseFloat(price) + 8 : _size == "3XL" ? parseFloat(price) + 4 : _size == "4XL" ? parseFloat(price) + 6 : _size == "5XL" ? parseFloat(price) + 8 : parseFloat(price)
          }
          for (const _color of colorList) {
            dataVariable.push([selectedValue, row[1], capitalized, removeLinksFromString(infoDescription), selectedSize.weight, selectedSize.lenght, selectedSize.width, selectedSize.height, row[8], row[9], row[10], _color, _size, row[13], priceSize, row[15], `${skuTime}${stt}-${sku}`, row[17], row[18], row[19], row[20], row[21], row[22], row[23], row[24], "", selectedSize.sizechart, "", "", "", "", "", "", "", "", "", "", "", "Not Sure", "None", ""])
            sku++
          }
        }
        stt++
      }
      if (allData.length) {
        const combinedData = [header, ...data2, ...dataVariable];
        const ws = XLSX.utils.aoa_to_sheet(combinedData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        XLSX.writeFile(wb, `old-tiktok_${moment().unix()}.xlsx`);
        dataVariable = []
        stt = 0
      } else {
        NotificationManager.error('Data cào vể rỗng!', 'Error', 3000);
      }
    }
  };

  function getFirstWords(text) {
    if (text.length > 100) {
      return text.substring(0, 100) + "...";
    } else {
      return text;
    }
  }

  return (
    <DashboardLayout>
      {isLoading && <LoadingScreen />}
      <DashboardNavbar />
      <MDBox pt={3} pb={3}>
        <Card>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4} lg={4} textAlign={"center"}>
              <MDBox pt={3} pb={3} style={{ alignSelf: "center", display: "flex", flexDirection: "column", padding: 1 }}>
                <input
                  accept="*"
                  style={{ display: 'none' }}
                  id="file-upload"
                  type="file"
                  onChange={handleFileUpload}
                />
                <label htmlFor="file-upload"  >
                  <MDButton variant="contained" color="info" component="span" sx={{ margin: 1 }}>
                    {fileName || 'Upload File'}
                  </MDButton>
                </label>
              </MDBox>
              <StyledFormControl sx={{ marginBottom: '16px', width: "100%", padding: 1 }}>
                <InputLabel id="dropdown-label">Danh mục</InputLabel>
                <Select
                  sx={{ height: "45px" }}
                  label="Danh mục"
                  labelId="dropdown-label"
                  value={selectedValue}
                  onChange={handleChange}>
                  {dropdown.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.acc}
                    </MenuItem>
                  ))}
                </Select>
              </StyledFormControl>
              <StyledFormControl sx={{ marginBottom: '16px', width: "100%", padding: 1 }}>
                <InputLabel>Loại</InputLabel>
                <Select
                  label="Loại"
                  sx={{ height: "45px" }}
                  value={selectedSize.name}
                  onChange={handleChangeSizes} >
                  {sizes.map((size, index) => (
                    <MenuItem key={index} value={size.name}>
                      {size.name}
                    </MenuItem>
                  ))}
                </Select>
              </StyledFormControl>
              <TextField
                label="Weight|Heigh|Width|Lenght"
                sx={{ marginBottom: '16px', width: "100%", padding: 1 }}
                value={`${selectedSize.weight}lb | ${selectedSize.height}in | ${selectedSize.width}in | ${selectedSize.lenght}in `}
              />
              <TextField
                label="Giá"
                sx={{ marginBottom: '16px', width: "100%", padding: 1 }}
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              />
              <Box sx={{ display: "flex", flexDirection: "row", width: "100%", alignItems: "center", justifyContent: "space-between", padding: 1 }}>
                <Box>
                  <Typography sx={{ margin: '10px', display: "flex", flexDirection: "row", fontSize: "10px" }}>{color ? color.split("|").map(color => (
                    <ColorItem key={color} color={color} />
                  )) : null}</Typography>
                  <Typography sx={{ margin: '10px', fontSize: "10px" }} >{size.split("|").join(", ")}</Typography>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <MDBadge badgeContent={`Edit`} onClick={() => handleClickOpen()} style={{ cursor: "pointer", margin: 2 }} color="success" variant="gradient" size="sm" />
                </Box>
              </Box>
              <MDButton variant="contained" onClick={handleExport} color="primary" component="span" sx={{ margin: 1 }}>
                Xuất
              </MDButton>
            </Grid>
            <Grid item xs={12} md={8} lg={8} sx={{ textAlign: "center" }}>
              <Typography sx={{ marginTop: 2, fontWeight: "bold" }}>Check Link</Typography>
              <div style={{ display: "flex", flexDirection: "row" }} >
                <TextField
                  autoFocus
                  margin="dense"
                  id="link"
                  label="Url"
                  type="text"
                  fullWidth
                  value={link}
                  onChange={event => setLink(event.target.value)}
                />
                <MDButton variant="contained" onClick={handleCheckLink} color="primary" component="span" sx={{ margin: 1 }}>
                  Check
                </MDButton>
              </div>
              {linkStatus ? linkStatus.toUpperCase() == "CHƯA CÓ LISTING TỪ LINK NÀY!" ? < MDBadge badgeContent={linkStatus} color="success" variant="gradient" size="lg" /> : < MDBadge badgeContent={linkStatus} color="error" variant="gradient" size="lg" /> : ""}
              <Typography sx={{ marginTop: 2, fontWeight: "bold" }}>Log Lỗi</Typography>
              <DataTable
                table={{
                  columns: [
                    { Header: "index", accessor: "index", align: "left" },
                    { Header: "url", accessor: "url", align: "left" },
                    { Header: "error", accessor: "error", align: "left" },
                  ],
                  rows: dataError && dataError.length > 0 ? [...dataError.map((items, index) => ({
                    index: <><MDBadge badgeContent={index.toString()} color={"info"} variant="gradient" size="sm" /></>,
                    url: <a href={items[0]}>{getFirstWords(items[0])}</a>,
                    error: <>
                      <MDBadge badgeContent={items[1]} color={"error"} variant="gradient" size="sm" /></>
                  })
                  )] : []
                }}
                isSorted={false}
                entriesPerPage={false}
                showTotalEntries={false}
                noEndBorder
              />
            </Grid>
          </Grid>
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Edit Size, Color</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                id="size"
                label="Size"
                type="text"
                fullWidth
                value={size}
                onChange={event => setSize(event.target.value)}
              />
              <TextField
                autoFocus
                margin="dense"
                id="color"
                label="Màu"
                type="text"
                fullWidth
                value={color}
                onChange={event => setColor(event.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Hủy
              </Button>
              <Button onClick={handleOk} color="primary">
                OK
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog open={open1} onClose={handleClose1}>
            <DialogTitle>Edit Description</DialogTitle>
            <DialogContent>
              <Box fullWidth sx={{ display: "flex", flexDirection: "column", justifyContent: "center", overflowX: 'auto', alignSelf: "center" }} >
                {description1 ? Object.keys(description1).map(e => (
                  <Box key={e}>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="dessciprion"
                      label={e.toUpperCase()}
                      type="text"
                      inputProps={{ style: { fontSize: "10px" } }}
                      sx={{ width: 550, }}
                      multiline
                      rows={20}
                      value={description[e]}
                      onChange={event => setDescription({ ...description, [e]: event.target.value })}
                    />
                  </Box>
                )) : null}</Box>

            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose1} color="primary">
                Hủy
              </Button>
              <Button onClick={handleOk1} color="primary">
                OK
              </Button>
            </DialogActions>
          </Dialog>
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout >
  );
}

export default Tables;
