/**
=========================================================
* 508Media TikTok Tool - v2.2.0
=========================================================

* Product Page: https://www.egeadcompany.com/product/material-dashboard-react
* Copyright 2023 Dev Egead Company (https://www.egeadcompany.com)

Coded by www.egeadcompany.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { Box, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import Card from "@mui/material/Card";

// 508Media TikTok Tool components
import MDBox from "components/MDBox";

// 508Media TikTok Tool example components
import { styled } from '@mui/system';
import MDButton from "components/MDButton";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import moment from "moment";
import PropTypes from 'prop-types';
import { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "react-notifications/lib/notifications.css";
import * as XLSX from 'xlsx';
import data2 from './constants';

//outside
const ColorItem = ({ color }) => {
  return (
    <div style={{ marginRight: "3px", textShadow: "1px 1px 1px rgba(0, 0, 0, 0.7)", color: color == "Light Pink" ? "#FFB6C1" : color == "Light Blue" ? "#ADD8E6" : color == "Ash" ? "#ecece0" : color == "Natural" ? "#E5D3BF" : color == "Beige" ? "#F5F5DC" : color.toLowerCase() }}>
      {`${color.replace(" ", "")}`}
    </div>
  );
};
ColorItem.propTypes = {
  color: PropTypes.string.isRequired,
};

const StyledFormControl = styled(FormControl)({
  width: 225,
});

const dropdown = [
  { acc: "Nữ - T-shirts (601302)", value: "T-shirts (601302)" },
  { acc: "Nữ - Blouses & Shirts (601265)", value: "Blouses & Shirts (601265)" },
  { acc: "Nữ - Hoodies & Sweaters (601213)", value: "Hoodies & Sweaters (601213)" },
  { acc: "Vest, Tank & Tube Tops (843400)", value: "Vest, Tank & Tube Tops (843400)" },
  { acc: "Nam - Shirts (601195)", value: "Shirts (601195)" },
  { acc: "Nam - T-shirts (601226)", value: "T-shirts (601226)" },
  { acc: "Nam - Hoodies & Sweaters (601295)", value: "Hoodies & Sweaters (601295)" },
  { acc: "Nam - Hoodies & Sweaters (601295)", value: "Hoodies & Sweaters (601295)" },
  { acc: "Mug (600042)", value: "Mugs (600042)" },
  { acc: "Women's Tote Bags (903176)", value: "Women's Tote Bags (903176)" },
  { acc: "Tumbler (600048)", value: "Water Bottles (600048)" },
  { acc: "Men Short (601196)", value: "Shorts (601196)" },
]
const sizes = [
  {
    "sizechart": "https://crawleretsy.nyc3.digitaloceanspaces.com/size-chart/T-shirts.PNG",
    "name": "T-Shirts",
    "height": "10",
    "lenght": "3",
    "weight": "0.45",
    "width": "10"
  },
  {
    "sizechart": "https://crawleretsy.nyc3.digitaloceanspaces.com/size-chart/T-shirts.PNG",
    "name": "Shirt, Hawwaian Shirt",
    "height": "10",
    "lenght": "3",
    "weight": "0.45",
    "width": "10"
  },
  {
    "sizechart": "https://crawleretsy.nyc3.digitaloceanspaces.com/size-chart/hoodies.PNG",
    "name": "Hoodies",
    "height": "12",
    "lenght": "4",
    "weight": "1.6",
    "width": "10"
  },
  {
    "sizechart": "https://crawleretsy.nyc3.digitaloceanspaces.com/size-chart/sweatshirt.PNG",
    "name": "Sweatshirts",
    "height": "12",
    "lenght": "4",
    "weight": "1.6",
    "width": "10"
  },
  {
    "sizechart": "https://imagecloud.nyc3.digitaloceanspaces.com/photo_2024-05-28_14-31-15.jpg",
    "name": "Tank Top",
    "height": "12",
    "lenght": "4",
    "weight": "1.6",
    "width": "10"
  },
  {
    "sizechart": "",
    "name": "Tumbler",
    "height": "7",
    "lenght": "4",
    "weight": "0.6",
    "width": "4"
  },
  {
    "sizechart": "",
    "name": "Tote",
    "height": "7",
    "lenght": "2",
    "weight": "0.45",
    "width": "7"
  },
  {
    "sizechart": "",
    "name": "Mug",
    "height": "5",
    "lenght": "4",
    "weight": "0.6",
    "width": "10"
  },
  {
    "sizechart": "https://p19-oec-ttp.tiktokcdn-us.com/tos-useast5-i-omjb5zjo8w-tx/907dd027a6874ba9b50f010f0ffa85e9~tplv-omjb5zjo8w-origin-jpeg.jpeg?from=522366036&height=4681&width=4345",
    "name": "Men Short",
    "height": "3",
    "lenght": "6",
    "weight": "0.5",
    "width": "6"
  },
]

function removeLinksFromString(inputString) {
  if (inputString) {
    const linkRegex = /https?:\/\/[^\s]+/g;
    const stringWithoutLinks = inputString.replace(linkRegex, '');
    return stringWithoutLinks;
  } else {
    return inputString
  }
}

//
function HandleList() {
  const [price, setPrice] = useState(22.49);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [mainImages, setMainImages] = useState("");
  const [image1, setImage1] = useState("");
  const [image2, setImage2] = useState("");
  const [sizeChart, setSizeChart] = useState("");
  const [whwl, setWhwl] = useState("0.45|10|10|3");
  const [size, setSize] = useState("S|M|L|XL|2XL|3XL|4XL|5XL");
  const [color, setColor] = useState("Natural|Black|Light pink|Light Blue|ASH|White");
  const [selectedSize, setSelectedSize] = useState(sizes[0]); // Mặc định là kích cỡ đầu tiên
  const [selectedValue, setSelectedValue] = useState(dropdown[0].value); // Mặc định là kích cỡ đầu tiên

  const handleChangeSizes = (event) => {
    const selectedSizeName = event.target.value;
    const selectedSizeObject = sizes.find(size => size.name === selectedSizeName);
    if (selectedSizeName == "T-Shirts") {
      setSize("S|M|L|XL|2XL|3XL|4XL|5XL")
    } else if (selectedSizeName == "Men Short") {
      setSize("S|M|L|XL|2XL|3XL|4XL|5XL")
    } else if (selectedSizeName == "Tank Top") {
      setSize("S|M|L|XL|2XL")
    } else if (selectedSizeName == "Mug") {
      setSize("11oz|15oz")
    } else if (selectedSizeName == "Tumbler") {
      setSize("12oz")
    } else if (selectedSizeName == "Tote") {
      setSize("No Size")
    } else {
      setSize("S|M|L|XL|2XL|3XL")
    }
    setSelectedSize(selectedSizeObject);
  };
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleExport = async () => {
    const sizeList = size.split("|");
    const colorList = color.split("|");
    const capitalized = name.split('-')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join('-');

    const skuTime = moment().unix()
    let sku = 0
    let dataVariable = []
    for (const _size of sizeList) {
      let priceSize = price
      if (selectedSize.name == "T-Shirts" || selectedSize.name == "Tank Top" || selectedSize.name == "Shirt, Hawwaian Shirt") {
        priceSize = _size == "2XL" ? parseFloat(price) + 1 : _size == "3XL" ? parseFloat(price) + 2 : _size == "4XL" ? parseFloat(price) + 3 : _size == "5XL" ? parseFloat(price) + 4 : parseFloat(price)
      } else if (selectedSize.name == "Men Short") {
        priceSize = parseFloat(price)
      } else {
        priceSize = _size == "2XL" ? parseFloat(price) + 2 : _size == "15oz" ? parseFloat(price) + 8 : _size == "15OZ" ? parseFloat(price) + 8 : _size == "3XL" ? parseFloat(price) + 4 : _size == "4XL" ? parseFloat(price) + 6 : _size == "5XL" ? parseFloat(price) + 8 : parseFloat(price)
      }
      for (const _color of colorList) {
        dataVariable.push([selectedValue, "", capitalized, removeLinksFromString(description), whwl.split("|")[0], whwl.split("|")[3], whwl.split("|")[2], whwl.split("|")[1], "", "UPC (3)", "", _color, _size, "", priceSize, "50", `${skuTime}-${sku}`, mainImages, image1, image2, "", "", "", "", "", "", selectedSize.sizechart, "", "", "", "", "", "", "", "", "", "", "", "Not Sure", "None", ""])
        sku++
      }
    }
    const combinedData = [...data2, ...dataVariable];
    const ws = XLSX.utils.aoa_to_sheet(combinedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, `tiktok_${moment().unix()}.xlsx`);

  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3} pb={3}>
        <Card style={{ textAlign: "center" }}>
          <Typography sx={{ marginTop: 2, fontWeight: "bold" }}>List Tay</Typography>
          <TextField
            label="Title"
            sx={{ marginBottom: '16px', width: "100%", marginLeft: 1, marginRight: 1 }}
            value={name}
            onChange={e => setName(e.target.value)}
          />
          <TextField
            label="Description"
            multiline
            rows={5}
            sx={{ marginBottom: '16px', width: "100%", marginLeft: 1, marginRight: 1 }}
            value={description}
            onChange={e => setDescription(e.target.value)}
          />
          <Box sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
            <TextField
              label="Main Image"
              sx={{ marginBottom: '16px', width: "100%", marginLeft: 1, marginRight: 1 }}
              value={mainImages}
              onChange={(e) => setMainImages(e.target.value)}
            />
            <TextField
              label="Image 2"
              sx={{ marginBottom: '16px', width: "100%", marginLeft: 1, marginRight: 1 }}
              value={image1}
              onChange={(e) => setImage1(e.target.value)}
            />
            <TextField
              label="Image 3"
              sx={{ marginBottom: '16px', width: "100%", marginLeft: 1, marginRight: 1 }}
              value={image2}
              onChange={(e) => setImage2(e.target.value)}
            />
            <TextField
              label="Size Chart"
              sx={{ marginBottom: '16px', width: "100%", marginLeft: 1, marginRight: 1 }}
              value={sizeChart}
              onChange={(e) => setSizeChart(e.target.value)}
            />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row", width: "100%", marginBottom: 2, paddingLeft: 2, paddingRight: 2 }}>
            <img src={mainImages} width={"25%"} style={{ borderRadius: "0.75rem", paddingLeft: 5, paddingRight: 5 }}></img>
            <img src={image1} width={"25%"} style={{ borderRadius: "0.75rem", paddingLeft: 5, paddingRight: 5 }}></img>
            <img src={image2} width={"25%"} style={{ borderRadius: "0.75rem", paddingLeft: 5, paddingRight: 5 }}></img>
            <img src={sizeChart} width={"25%"} style={{ borderRadius: "0.75rem", paddingLeft: 5, paddingRight: 5 }}></img>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <StyledFormControl sx={{ marginBottom: '16px', width: "100%", marginLeft: 1, marginRight: 1 }}>
              <InputLabel id="dropdown-label">Danh mục</InputLabel>
              <Select
                sx={{ height: "45px" }}
                label="Danh mục"
                labelId="dropdown-label"
                value={selectedValue}
                onChange={handleChange}>
                {dropdown.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.acc}
                  </MenuItem>
                ))}
              </Select>
            </StyledFormControl>
            <StyledFormControl sx={{ marginBottom: '16px', width: "100%", marginLeft: 1, marginRight: 1 }}>
              <InputLabel>Loại</InputLabel>
              <Select
                label="Loại"
                sx={{ height: "45px" }}
                value={selectedSize.name}
                onChange={handleChangeSizes}
              >
                {sizes.map((size, index) => (
                  <MenuItem key={index} value={size.name}>
                    {size.name}
                  </MenuItem>
                ))}
              </Select>
            </StyledFormControl>
            <TextField
              label="Weight (lb) | Heigh (in) | Width (in) | Lenght (in)"
              sx={{ marginBottom: '16px', width: "100%", marginLeft: 1, marginRight: 1 }}
              value={whwl}
              onChange={e => setWhwl(e.target.value)}
            />
            <TextField
              label="Giá"
              sx={{ marginBottom: '16px', width: "100%", marginLeft: 1, marginRight: 1 }}
              value={price}
              onChange={(e) => setPrice(e.target.value)}
            />
          </Box>

          <Box sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
            <TextField
              label="Size"
              sx={{ marginBottom: '16px', width: "100%", marginLeft: 1, marginRight: 1 }}
              value={size}
              onChange={(e) => setSize(e.target.value)}
            />
            <TextField
              label="Color"
              sx={{ marginBottom: '16px', width: "100%", marginLeft: 1, marginRight: 1 }}
              value={color}
              onChange={(e) => setColor(e.target.value)}
            />
          </Box>
          <MDButton variant="contained" onClick={handleExport} color="primary" component="span" sx={{ margin: 1 }}>
            Xuất
          </MDButton>
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout >
  );
}

export default HandleList;
